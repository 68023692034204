export const DIMENSION_NULL_VALUE =
	"NULL_VALUE_THAT_SHOULD_BE_REPLACED_WITH_NULL";
export const DIMENSION_NULL_LABEL = "N/A";

export const replaceNullDimensionIdWithNull = (dimensionId: string | null) => {
	if (dimensionId === DIMENSION_NULL_VALUE) {
		return null;
	}
	return dimensionId;
};
