import {
	createContext,
	type Dispatch,
	type SetStateAction,
	useContext,
} from "react";
import type { SieValidateResponse } from "src/common/redux/api/exopenApiTypes";

export const SiePageContext = createContext<
	| {
			value: SieValidateResponse | undefined;
			setValue: Dispatch<SetStateAction<SieValidateResponse | undefined>>;
	  }
	| undefined
>(undefined);

export const useSiePage = () => {
	const context = useContext(SiePageContext);
	if (context === undefined) {
		throw new Error("useSiePage must be used within a SiePageContext");
	}
	return context;
};
