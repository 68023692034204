import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "../../common/components/atoms/alert/Alert";
import { Text } from "../../common/components/atoms/typography/Text";
import { FileUploader } from "../../common/components/organism/fileUploader";
import {
	useDeleteSiePeriodMutation,
	useValidateSieFileMutation,
} from "../../common/redux/api/exopenApi";
import { useDocumentTitle } from "../../common/utils/hooks/useDocumentTitle";
import { usePersistedNavigate } from "../../common/persistentRouterFunctions";
import { useLegalEntityQueryParameters } from "../../common/context/legacyLegalEntityContextUtils";
import { showErrorNotification } from "../../common/components/atoms/notifications/events";
import { IconDatabase, IconTrash } from "@tabler/icons-react";
import { BreadcrumbsLayout } from "../../common/components/layout/BreadcrumbsLayout";
import { Button } from "components/button";
import { SwitchField } from "components/checkbox/SwitchField";
import { ConfirmDialog } from "../../common/components/atoms/dialog/ConfirmDialog.tsx";
import { useSiePage } from "src/features/uploadSie/SiePageContext.ts";
import { useCurrentCompanyDomainId } from "src/common/company-domain/useCurrentCompanyDomain.ts";

enum ViewState {
	UploadNewFile = 0,
	UploadFailedGeneralError = 1,
	UploadFailedParserError = 2,
	UploadFailedFaultyAccounts = 3,
	UploadFailedOverlappingPeriods = 4,
}

export const UploadSie = () => {
	const { value: sieData, setValue: updateSieData } = useSiePage();
	const { t } = useTranslation();
	const navigate = usePersistedNavigate();
	const search = useLegalEntityQueryParameters();
	const [viewState, setViewState] = useState(ViewState.UploadNewFile);
	const { companyDomainId } = useCurrentCompanyDomainId();

	const [validateSie, { isLoading }] = useValidateSieFileMutation();

	const [deleteSiePeriod, { isLoading: deletingSiePeriod }] =
		useDeleteSiePeriodMutation();

	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
	const [siePeriodsToDelete, setSiePeriodsToDelete] = useState<string[]>([]);

	const validateUploadedFile = async (addedFiles: Array<File>) => {
		const result = await validateSie({
			file: addedFiles[0],
			companyId: companyDomainId,
		});
		if ("error" in result) {
			showErrorNotification({
				message: t("File could not be uploaded"),
			});
			setViewState(ViewState.UploadFailedGeneralError);
		} else {
			updateSieData(result.data);
			if (result.data.isValid) {
				navigate({ pathname: "validate", ...(search ? { search } : {}) });
			} else if (result.data.parserError?.message) {
				setViewState(ViewState.UploadFailedParserError);
			} else if (
				result.data.accountResultReport.notOkayAccounts.length > 0 ||
				result.data.accountBalanceReport.notOkayAccounts.length > 0
			) {
				setViewState(ViewState.UploadFailedFaultyAccounts);
			} else if (result.data.overlappingPeriods.length > 0) {
				setUploadedFiles(addedFiles);
				setViewState(ViewState.UploadFailedOverlappingPeriods);
			} else {
				setViewState(ViewState.UploadFailedGeneralError);
			}
		}
	};

	const getFaultyAccounts = () => {
		if (!sieData) {
			return null;
		}
		return [
			...sieData.accountResultReport.notOkayAccounts,
			...sieData.accountBalanceReport.notOkayAccounts,
		].map((account) => (
			<Fragment key={account.number}>
				<br />
				<br />
				{t("Account number")}
				{": "}
				{account.number}
				{", "}
				{t("description")}
				{": "}
				{account.description}
			</Fragment>
		));
	};

	const getOverlappingPeriods = () => {
		if (!sieData) {
			return null;
		}

		return (
			<div className="mb-3 mt-3">
				{sieData.overlappingPeriods.map((period) => (
					<SwitchField
						labelColor="text-red-500"
						key={period.fiscalYearEntityId}
						label={`${period.fromDate.slice(0, 10)} to ${period.toDate.slice(
							0,
							10,
						)}`}
						checked={siePeriodsToDelete.includes(period.fiscalYearEntityId)}
						onChange={() => {
							siePeriodsToDelete.includes(period.fiscalYearEntityId)
								? setSiePeriodsToDelete(
										siePeriodsToDelete.filter(
											(id) => id !== period.fiscalYearEntityId,
										),
									)
								: setSiePeriodsToDelete([
										...siePeriodsToDelete,
										period.fiscalYearEntityId,
									]);
						}}
					/>
				))}
			</div>
		);
	};

	const closeAlert = () => {
		setViewState(ViewState.UploadNewFile);
	};

	const onDeleteConfirm = async () => {
		const promises = siePeriodsToDelete.map((fiscalYearEntityId) =>
			deleteSiePeriod({
				companyId: companyDomainId,
				fiscalYearEntityId,
			}),
		);
		const results = await Promise.all(promises);
		for (const result of results) {
			if ("error" in result) {
				showErrorNotification({
					message: `${t("SIE periods could not be deleted")}...`,
				});
			}
		}

		setConfirmModalOpen(false);
		setViewState(ViewState.UploadNewFile);
		await validateUploadedFile(uploadedFiles);
	};

	const pageName = t("Upload SIE4-files");
	useDocumentTitle(pageName);

	return (
		<BreadcrumbsLayout
			breadcrumbs={[{ icon: <IconDatabase /> }, { name: pageName }]}
		>
			<Text className="mb-2" size="md">
				{t("Here you can upload SIE4 files from your financial system.")}
			</Text>
			<FileUploader
				dropText={t("Drag and drop a SIE4 file here (one file only)")}
				accept={{ "text/plain": [".se"] }}
				maxFiles={1}
				onDraggedFiles={validateUploadedFile}
				isLoading={isLoading}
			/>
			{viewState === ViewState.UploadFailedGeneralError && (
				<Alert
					title={t("File could not be uploaded")}
					color="error"
					onClose={closeAlert}
					supportingText={t(
						"An unknown error occured when the file was uploaded, please contact support",
					)}
				/>
			)}
			{viewState === ViewState.UploadFailedParserError && (
				<Alert
					title={t("File could not be uploaded")}
					color="error"
					onClose={closeAlert}
					supportingText={
						<>
							{t("An validation error occured when the file was uploaded")}
							{": "}
							<br />
							{sieData?.parserError?.message}
						</>
					}
				/>
			)}
			{viewState === ViewState.UploadFailedFaultyAccounts && (
				<Alert
					title={t("File could not be uploaded")}
					onClose={closeAlert}
					color="error"
					supportingText={
						<>
							{t("Following accounts did not pass the validation")}
							{": "}
							{getFaultyAccounts()}
						</>
					}
				/>
			)}
			{viewState === ViewState.UploadFailedOverlappingPeriods && (
				<>
					<Alert
						title={t("File could not be uploaded")}
						onClose={closeAlert}
						color="error"
						supportingText={
							<>
								{t(
									"The legal entity in this SIE-file already has previously uploaded data for the following overlapping periods",
								)}
								{": "}
								{getOverlappingPeriods()}
								{t(
									"In order to upload this SIE-file the above overlapping periods must be deleted or data load will fail. Please select the periods you wish to delete or contact support.",
								)}
								{siePeriodsToDelete.length > 0 && (
									<>
										<br />
										<br />
										<Button
											ariaLabel="Delete SIE period"
											icon={<IconTrash />}
											variant="primary"
											destructive
											size="sm"
											disabled={deletingSiePeriod}
											isLoading={deletingSiePeriod}
											onClick={() => {
												setConfirmModalOpen(true);
											}}
										>
											{t("Delete SIE periods")}
										</Button>
									</>
								)}
							</>
						}
					/>
					<ConfirmDialog
						destructive
						open={confirmModalOpen}
						onClose={() => {
							setConfirmModalOpen(false);
						}}
						onConfirm={onDeleteConfirm}
						isLoading={deletingSiePeriod}
						title={t("Delete SIE periods")}
						confirmButtonLabel={t("Remove")}
						description={t(
							"Are you sure you want to remove these SIE periods?",
						)}
					/>
				</>
			)}
		</BreadcrumbsLayout>
	);
};
