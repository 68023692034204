import { useId, useState } from "react";
import { SieAccountsTable } from "./SieAccountsTable";
import type { SieAccountGroup } from "../../common/redux/api/exopenApiTypes";
import { Text } from "../../common/components/atoms/typography/Text";
import { PlanningTable2 } from "../planning/components/PlanningTable";
import { CollapsibleRow } from "../../common/components/atoms/table/CollapsibleRow";
import { ToggleCell } from "../../common/components/atoms/table/ToggleCell";

const numberFormatter = new Intl.NumberFormat("sv-SE", {
	style: "currency",
	currency: "SEK",
});

interface SieTableRowProps {
	accountGroup: SieAccountGroup;
}

export const SieTableRow = ({ accountGroup }: SieTableRowProps) => {
	const [open, setOpen] = useState(false);

	const toggleRow = () => {
		setOpen(!open);
	};

	const uid = useId();
	const collapseDuration = 200;

	return (
		<>
			<tr>
				{!accountGroup.isSummationEntry ? (
					<>
						<ToggleCell
							isOpen={open}
							onToggle={toggleRow}
							transitionDuration={collapseDuration}
							aria-labelledby={uid}
						/>
						<PlanningTable2.Td className="whitespace-nowrap py-2">
							<button onClick={toggleRow} tabIndex={-1} id={uid}>
								<Text size="xs" weight="medium" color="text-grey-500">
									{accountGroup.name}
								</Text>
							</button>
						</PlanningTable2.Td>
					</>
				) : (
					<>
						<PlanningTable2.Td />
						<PlanningTable2.Td className="whitespace-nowrap py-2">
							<Text size="xs" weight="medium" color="text-grey-500">
								{accountGroup.name}
							</Text>
						</PlanningTable2.Td>
					</>
				)}
				<PlanningTable2.Td className="whitespace-nowrap py-2">
					<Text size="xs" weight="medium" color="text-grey-500">
						{accountGroup.accountFrom} - {accountGroup.accountTo}
					</Text>
				</PlanningTable2.Td>
				<PlanningTable2.Td className="py-2 text-right">
					<Text size="xs" weight="medium" color="text-grey-500">
						{numberFormatter.format(accountGroup.amount)}
					</Text>
				</PlanningTable2.Td>
			</tr>
			{!accountGroup.isSummationEntry && (
				<tr>
					<td colSpan={4} className="p-0">
						<CollapsibleRow
							open={open}
							collapseDuration={collapseDuration}
							className="col-span-4"
							innerContainerClassName="p-4"
						>
							<SieAccountsTable accounts={accountGroup.accounts} />
						</CollapsibleRow>
					</td>
				</tr>
			)}
		</>
	);
};
