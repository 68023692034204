import { DIMENSION_NULL_LABEL } from "./nullDimension.js";

export const getDimensionLabel = (
	dimension:
		| {
				code: string | number;
				name: string;
		  }
		| null
		| undefined,
) =>
	dimension ? `${dimension.code} - ${dimension.name}` : DIMENSION_NULL_LABEL;
