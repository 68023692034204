import { Outlet } from "react-router";
import { useMemo, useState } from "react";
import type { SieValidateResponse } from "../../common/redux/api/exopenApiTypes";
import { SiePageContext } from "src/features/uploadSie/SiePageContext";

export const SiePage = () => {
	const [state, setState] = useState<SieValidateResponse>();

	const contextValue = useMemo(() => {
		return {
			value: state,
			setValue: setState,
		};
	}, [state]);

	return (
		<SiePageContext.Provider value={contextValue}>
			<Outlet />
		</SiePageContext.Provider>
	);
};
