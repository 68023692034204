import { useTranslation } from "react-i18next";
import { useDeleteCompanyMutation } from "../../common/redux/api/exopenApi.ts";
import { ConfirmDialog } from "../../common/components/atoms/dialog/ConfirmDialog.tsx";
import { useCurrentCompanyDomain } from "../../common/company-domain/useCurrentCompanyDomain.ts";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events.tsx";
import { Button } from "../../common/components/atoms/button/Button.tsx";
import { IconTrash } from "@tabler/icons-react";
import { useNavigate } from "react-router";
import { useUser } from "../../common/context/userContextUtils.ts";
import { Tooltip } from "../../common/components/atoms/tooltip/Tooltip.tsx";
import { TooltipTrigger } from "../../common/components/atoms/tooltip/TooltipTrigger.tsx";
import { TooltipContent } from "../../common/components/atoms/tooltip/TooltipContent.tsx";

export const DeleteCompanyDomainDialog = () => {
	const { companyDomain } = useCurrentCompanyDomain();
	const { t } = useTranslation();
	const [deleteCompanyDomain, { isLoading: isDeleting }] =
		useDeleteCompanyMutation();
	const user = useUser();
	const navigate = useNavigate();
	const handleDelete = async () => {
		const result = await deleteCompanyDomain({
			companyId: companyDomain.id,
		});
		if ("error" in result) {
			showErrorNotification({
				message: t("The company domain couldn't be removed"),
			});
		} else {
			showSuccessNotification({ message: t("The company domain was removed") });
			navigate("/");
		}
	};

	const deleteButton = (
		<Button
			destructive
			size="sm"
			icon={<IconTrash />}
			disabled={!user.userIsSuperAdmin()}
		>
			{t("Delete company domain")}
		</Button>
	);

	return (
		<ConfirmDialog
			destructive
			onConfirm={handleDelete}
			isLoading={isDeleting}
			title={t("Delete company domain")}
			confirmButtonLabel={t("Remove")}
			description={t(
				"Are you sure you want to delete this company domain? This action can not be undone.",
			)}
		>
			{user.userIsSuperAdmin() ? (
				deleteButton
			) : (
				<Tooltip>
					<TooltipTrigger asChild>{deleteButton}</TooltipTrigger>
					<TooltipContent>
						{t(
							"Please contact Exopen support if you wish to delete your company domain.",
						)}
					</TooltipContent>
				</Tooltip>
			)}
		</ConfirmDialog>
	);
};
