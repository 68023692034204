import { IconChevronRight } from "@tabler/icons-react";
import { clsx } from "clsx";
import type { ComponentProps } from "react";
import { PlanningTable2 } from "../../../../features/planning/components/PlanningTable";

interface ToggleCellProps extends ComponentProps<typeof PlanningTable2.Td> {
	onToggle: () => void;
	isOpen: boolean;
	transitionDuration?: number;
}

export const ToggleCell = ({
	onToggle,
	isOpen,
	transitionDuration,
	...other
}: ToggleCellProps) => {
	return (
		<PlanningTable2.Td className="w-0" {...other}>
			<button
				className={clsx("flex", isOpen ? "[&>svg]:rotate-90" : undefined)}
				onClick={onToggle}
			>
				<IconChevronRight
					style={
						transitionDuration
							? { transition: `transform ${transitionDuration}ms ease` }
							: undefined
					}
					className="mx-1 text-purple-500"
					size={20}
				/>
			</button>
		</PlanningTable2.Td>
	);
};
