import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "../../common/components/atoms/pagination/Pagination";
import { SortableTableCell } from "../../common/components/atoms/table/SortableTableCell";
import { Text } from "../../common/components/atoms/typography/Text";
import type { SieAccount } from "../../common/redux/api/exopenApiTypes";
import { getComparator, sortItems } from "../../common/utils/sortHelper";
import {
	PlanningTable2,
	PlanningTableContainer,
} from "../planning/components/PlanningTable";
import { getDimensionLabel } from "../planning/scenario/common/getDimensionLabel.js";

const numberFormatter = new Intl.NumberFormat("sv-SE", {
	style: "currency",
	currency: "SEK",
});

interface SieAccountsTableProps {
	accounts: SieAccount[];
}

const rowsPerPage = 10;

export const SieAccountsTable = ({ accounts }: SieAccountsTableProps) => {
	const [order, setOrder] = useState<"asc" | "desc">("asc");
	const [page, setPage] = useState(1);
	const { t } = useTranslation();

	const createSortHandler = () => {
		setOrder(order === "asc" ? "desc" : "asc");
	};

	return (
		<>
			<PlanningTableContainer>
				<PlanningTable2>
					<thead>
						<tr>
							<SortableTableCell
								order={order}
								active={true}
								onClick={createSortHandler}
							>
								{t("Account")}
							</SortableTableCell>
							<PlanningTable2.Th className="text-right">
								{t("Amount")}
							</PlanningTable2.Th>
						</tr>
					</thead>
					<tbody>
						{sortItems(accounts, getComparator(order, "code"))
							.slice(
								(page - 1) * rowsPerPage,
								(page - 1) * rowsPerPage + rowsPerPage,
							)
							.map((account) => (
								<tr key={account.code}>
									<PlanningTable2.Td className="py-1">
										<Text size="xs" weight="medium" color="text-grey-500">
											{getDimensionLabel(account)}
										</Text>
									</PlanningTable2.Td>
									<PlanningTable2.Td className="py-1 text-right">
										<Text size="xs" weight="medium" color="text-grey-500">
											{numberFormatter.format(account.amount)}
										</Text>
									</PlanningTable2.Td>
								</tr>
							))}
					</tbody>
				</PlanningTable2>
			</PlanningTableContainer>
			<Pagination
				totalPages={Math.ceil(accounts.length / rowsPerPage)}
				page={page}
				onChange={setPage}
			/>
		</>
	);
};
