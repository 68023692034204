import { SieTableRow } from "./SieTableRow";
import { useTranslation } from "react-i18next";
import {
	PlanningTable2,
	PlanningTableContainer,
} from "../planning/components/PlanningTable";
import { useSiePage } from "src/features/uploadSie/SiePageContext";
import { Navigate } from "react-router";
import type {
	SieAccountPlan,
	SieValidateResponse,
} from "src/common/redux/api/exopenApiTypes";

export const SieReportTable = ({
	getReport,
}: { getReport: (value: SieValidateResponse) => SieAccountPlan }) => {
	const { value } = useSiePage();
	const { t } = useTranslation();
	if (!value) {
		return <Navigate to="./.." />;
	}
	const accountPlan = getReport(value);

	return (
		<PlanningTableContainer>
			<PlanningTable2>
				<thead>
					<tr>
						<PlanningTable2.Th />
						<PlanningTable2.Th>{t("Name")}</PlanningTable2.Th>
						<PlanningTable2.Th>{t("Accounts")}</PlanningTable2.Th>
						<PlanningTable2.Th className="text-right">
							{t("Amount")}
						</PlanningTable2.Th>
					</tr>
				</thead>

				<tbody>
					{accountPlan.accountGroups.map((accountGroup) => (
						<SieTableRow key={accountGroup.name} accountGroup={accountGroup} />
					))}
				</tbody>
			</PlanningTable2>
		</PlanningTableContainer>
	);
};
