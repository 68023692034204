import { useState } from "react";

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
	const aV = a[orderBy];
	const bV = b[orderBy];

	if (Array.isArray(aV) && Array.isArray(bV)) {
		if (bV.length < aV.length) {
			return -1;
		}
		if (bV.length > aV.length) {
			return 1;
		}
		return 0;
	}

	if (typeof aV === "string" && typeof bV === "string") {
		return aV.localeCompare(bV);
	}

	if (bV < aV) {
		return -1;
	}
	if (bV > aV) {
		return 1;
	}
	return 0;
};

export const getComparator = <Key extends keyof any>(
	order: "asc" | "desc",
	orderBy: Key,
): ((
	a: { [key in Key]: number | string | unknown[] },
	b: { [key in Key]: number | string | unknown[] },
) => number) => {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

export const sortItems = <T>(
	items: T[],
	comparator: (a: T, b: T) => number,
) => {
	const stabilizedThis = items.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
};

export const useSort = <Field extends string>(
	defaultField: Field,
	defaultDirection: "asc" | "desc",
) => {
	const [sort, setSort] = useState<{
		field: Field;
		direction: "asc" | "desc";
	}>({ field: defaultField, direction: defaultDirection });

	return {
		sort,
		getSortProps: (field: Field) => {
			return {
				selected: sort.field === field,
				order: sort.direction,
				onClick: () => {
					setSort({
						field,
						direction: sort.direction === "asc" ? "desc" : "asc",
					});
				},
			};
		},
	};
};
