import { clsx } from "clsx";
import type { ComponentPropsWithRef, ReactNode } from "react";
import { Collapse } from "../collapse/Collapse";

interface CollapsibleRowProps extends ComponentPropsWithRef<"div"> {
	children?: ReactNode | undefined;
	open: boolean;
	collapseDuration?: number;
	innerContainerClassName?: string;
}

export const CollapsibleRow = ({
	children,
	open,
	collapseDuration,
	innerContainerClassName,
	...other
}: CollapsibleRowProps) => {
	return (
		<div {...other}>
			<Collapse in={open} transitionDuration={collapseDuration}>
				<div
					className={clsx("border-b border-grey-200", innerContainerClassName)}
				>
					{children}
				</div>
			</Collapse>
		</div>
	);
};
