import { SiePage } from "src/features/uploadSie/SiePage";
import { UploadSiePage } from "src/features/uploadSie/UploadSiePage";
import { useLoadedCompanyDomain } from "src/routes/routeDataHooks";

export const SiePageDivider = () => {
	const companyDomain = useLoadedCompanyDomain();

	if (
		companyDomain.pumps.find((pump) => pump.pumpType === "Sie") === undefined
	) {
		return <UploadSiePage />;
	}

	return <SiePage />;
};
