import { useTranslation } from "react-i18next";
import classes from "./CompanyDashboard.module.css";
import ExopenPlanningIcon from "./product-icons/planning.svg?react";
import ExopenReportIcon from "./product-icons/report.svg?react";
import ExopenConsolidationIcon from "./product-icons/consolidation.svg?react";
import DataLayerIcon from "../../assets/images/icons/datalayer.svg?react";
import { clsx } from "clsx";
import { Display } from "../../common/components/atoms/typography/Display";
import { PersistedLink } from "../../common/persistentRouterFunctions.tsx";
import { useDocumentTitle } from "../../common/utils/hooks/useDocumentTitle.ts";
import { useUser } from "../../common/context/userContextUtils.ts";
import { useLegalEntityQueryParameters } from "../../common/context/legacyLegalEntityContextUtils.ts";
import { isDefined } from "../../common/utils/filters/isDefined.ts";
import { useLoadedCompanyDomain } from "src/routes/routeDataHooks.ts";

export const CompanyDashboard = () => {
	const { t } = useTranslation();
	const companyDomain = useLoadedCompanyDomain();
	const { user } = useUser();
	const search = useLegalEntityQueryParameters();
	useDocumentTitle("Start");

	const products = [
		{
			name: "Data Layer",
			className: classes.analytics,
			path: `/company-domains/${companyDomain.company.id}/datalayer`,
			description: t("Exopens generalized data layer"),
			icon: <DataLayerIcon style={{ color: "#ff9533", height: 30 }} />,
			classic: false,
		},
		{
			name: "Planning",
			className: classes.planning,
			path: `/company-domains/${companyDomain.company.id}/planning`,
			description: t("Efficient and user-friendly budget and forecast"),
			icon: <ExopenPlanningIcon />,
			classic: false,
		},
		{
			name: "Report",
			className: classes.report,
			path: companyDomain.ewr?.url,
			description: t("Customized reporting in Excel/Web"),
			icon: <ExopenReportIcon />,
			classic: true,
		},
		{
			name: "Consolidation",
			className: classes.consolidation,
			path: companyDomain.ewr?.url,
			description: t("A comprehensive consolidation tool"),
			icon: <ExopenConsolidationIcon />,
			classic: true,
		},
	]
		.filter(isDefined)
		.filter((product) => {
			if (product.classic && !companyDomain.ewr?.url) {
				return false;
			}
			return true;
		});

	return (
		<div className={classes.companyDashboard}>
			<div className={clsx("mb-2", classes.greeting)}>
				<span>{t("Welcome")}</span> {user.name}
			</div>
			<Display weight="bold" size="lg" className="mb-6">
				{t("We take your financial planning and analysis to the next level!")}
			</Display>

			<p className={clsx(classes.description, "mb-8")}>
				{t(
					"Make your everyday life more efficient with Exopen's easy-to-use products that give you insightful analysis in Power BI, agile budgeting, consolidation and customized reporting. You get everything in Exopen Cloud, with ready-made integrations and with guaranteed data quality.",
				)}
			</p>

			<section className={classes.ctaProducts}>
				{products.map((product) => {
					const card = (
						<>
							<div>
								<span>Exopen</span>
								<span>{product.name}</span>
							</div>
							<div>
								<div className="grow">{product.description}</div>
								{product.icon}
							</div>
						</>
					);

					if (product.classic) {
						return (
							<a
								key={product.className}
								href={product.path}
								className={clsx(classes.ctaLink, product.className)}
								target="_blank"
								rel="noreferrer"
							>
								{card}
							</a>
						);
					}

					return (
						<PersistedLink
							key={product.className}
							className={clsx(classes.ctaLink, product.className)}
							to={{
								...(product.path ? { pathname: product.path } : {}),
								...(search ? { search } : {}),
							}}
						>
							{card}
						</PersistedLink>
					);
				})}
			</section>
		</div>
	);
};
