import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useResolvedPath, useMatch, Outlet } from "react-router";
import { Alert } from "../../common/components/atoms/alert/Alert";
import { Button } from "../../common/components/atoms/button/Button";
import { useDocumentTitle } from "../../common/utils/hooks/useDocumentTitle";
import {
	PersistedLink,
	usePersistedNavigate,
} from "../../common/persistentRouterFunctions";
import { Tabs } from "../../common/components/atoms/tabs/Tabs";
import { Tab } from "../../common/components/atoms/tabs/Tab";
import {
	useApproveSieFileMutation,
	useStartMainPipelineMutation,
} from "../../common/redux/api/exopenApi";
import { IconDatabase } from "@tabler/icons-react";
import { BreadcrumbsLayout } from "../../common/components/layout/BreadcrumbsLayout";
import { RadioField } from "components/checkbox/RadioField";
import { Dialog } from "components/dialog/Dialog.tsx";
import { DialogContent } from "components/dialog/DialogContent.tsx";
import { DialogTitle } from "components/dialog/DialogTitle.tsx";
import { DialogBody } from "components/dialog/DialogBody.tsx";
import { DialogActions } from "components/dialog/DialogActions.tsx";
import { useSiePage } from "src/features/uploadSie/SiePageContext";
import { useCurrentCompanyDomainId } from "src/common/company-domain/useCurrentCompanyDomain";

interface Tab {
	title: string;
	path: string;
}

enum ReportTab {
	ResultReport = 0,
	BalanceReport = 1,
}

const useIsTabActive = (tab: Tab) => {
	const resolved = useResolvedPath(tab.path);
	const matchUsers = useMatch({ path: resolved.pathname, end: true });
	return matchUsers !== null;
};

enum ViewState {
	initial = 0,
	isLoading = 1,
	error = 2,
	successfulUpload = 3,
	confirmUpload = 4,
}

enum SieUploadOptions {
	approveSingleSieFile = 0,
	addAnotherSieFile = 1,
	triggerFullDatarun = 2,
}

export const SieResultPage = () => {
	const { value: sieData } = useSiePage();
	const navigate = usePersistedNavigate();
	const { t } = useTranslation();
	const { companyDomainId } = useCurrentCompanyDomainId();
	const [startMainPipeline] = useStartMainPipelineMutation();
	const [viewState, setViewState] = useState(ViewState.initial);
	const [confirmUploadRadio, setConfirmUploadRadio] = useState(
		SieUploadOptions.approveSingleSieFile,
	);
	const tabs: Record<string, Tab> = {
		[ReportTab.ResultReport]: {
			title: t("Income statement"),
			path: "./",
		},
		[ReportTab.BalanceReport]: {
			title: t("Balance sheet"),
			path: "./balance",
		},
	};
	const tab = useIsTabActive(tabs[ReportTab.ResultReport])
		? ReportTab.ResultReport
		: ReportTab.BalanceReport;

	const goBackToSieUpload = () => {
		navigate("..");
	};

	const pageName = tabs[tab].title;
	useDocumentTitle(pageName);

	const [approveSie] = useApproveSieFileMutation();

	if (!sieData) {
		goBackToSieUpload();
		return null;
	}

	const onSuccess = async () => {
		setViewState(ViewState.isLoading);
		const approveSieResult = await approveSie({
			companyId: companyDomainId,
			companyNameFromSie: sieData.companyName,
			organizationNumberFromSie: sieData.organizationNumber,
			sieId: sieData.sieId,
			runDataLoadOnApprove:
				confirmUploadRadio === SieUploadOptions.approveSingleSieFile,
		});

		if ("error" in approveSieResult) {
			setViewState(ViewState.error);
			return;
		}

		if (confirmUploadRadio === SieUploadOptions.addAnotherSieFile) {
			goBackToSieUpload();
		}

		if (confirmUploadRadio === SieUploadOptions.triggerFullDatarun) {
			const startMainPipelineResult = await startMainPipeline({
				companyId: companyDomainId,
			});

			if ("error" in startMainPipelineResult) {
				setViewState(ViewState.error);
				return;
			}
		}

		setViewState(ViewState.successfulUpload);
	};

	const SuccessModal = () => {
		if (viewState !== ViewState.successfulUpload) {
			return null;
		}

		const successTitle =
			confirmUploadRadio === SieUploadOptions.triggerFullDatarun
				? t("A data run has begun")
				: t("Your file was successfully uploaded");

		const successContent =
			confirmUploadRadio === SieUploadOptions.triggerFullDatarun
				? t(
						"Your file was uploaded and will appear under datasources in a short while. A data run for all legal entities has also begun",
					)
				: t(
						"Your file was upload and will appear under datasource in a short while",
					);

		return (
			<Dialog open onClose={goBackToSieUpload}>
				<DialogContent>
					<DialogTitle>{successTitle}</DialogTitle>
					<DialogBody>{successContent}</DialogBody>
					<DialogActions withCancelButton />
				</DialogContent>
			</Dialog>
		);
	};

	return (
		<BreadcrumbsLayout
			breadcrumbs={[
				{ icon: <IconDatabase /> },
				{ name: t("Validate SIE-file") },
				{ name: pageName },
			]}
			headerContent={
				<div>
					<Button
						variant="secondaryGray"
						onClick={goBackToSieUpload}
						style={{ marginRight: 12 }}
					>
						{t("Cancel")}
					</Button>
					<Button
						onClick={() => setViewState(ViewState.confirmUpload)}
						isLoading={viewState === ViewState.isLoading}
					>
						{t("Confirm upload")}
					</Button>
				</div>
			}
		>
			<Tabs selectedIndex={tab} className="mb-6">
				<Tab as={PersistedLink} to={tabs[ReportTab.ResultReport].path}>
					{t("Income statement")}
				</Tab>
				<Tab as={PersistedLink} to={tabs[ReportTab.BalanceReport].path}>
					{t("Balance sheet")}
				</Tab>
			</Tabs>
			<Dialog
				open={viewState === ViewState.confirmUpload}
				onClose={() => setViewState(ViewState.initial)}
			>
				<DialogContent>
					<DialogTitle>{t("Select option")}</DialogTitle>
					<DialogBody>
						<div className="py-3">
							<RadioField
								checked={
									confirmUploadRadio === SieUploadOptions.approveSingleSieFile
								}
								onChange={() =>
									setConfirmUploadRadio(SieUploadOptions.approveSingleSieFile)
								}
								label={t(
									"Upload and start data run for this SIE-files legal entity",
								)}
							/>
						</div>
						<div className="py-3">
							<RadioField
								checked={
									confirmUploadRadio === SieUploadOptions.addAnotherSieFile
								}
								onChange={() =>
									setConfirmUploadRadio(SieUploadOptions.addAnotherSieFile)
								}
								label={t("Add another SIE-file")}
							/>
						</div>
						<div className="py-3">
							<RadioField
								checked={
									confirmUploadRadio === SieUploadOptions.triggerFullDatarun
								}
								onChange={() =>
									setConfirmUploadRadio(SieUploadOptions.triggerFullDatarun)
								}
								label={t(
									"Upload SIE-file and start data run for all legal entities",
								)}
							/>
						</div>
					</DialogBody>
					<DialogActions withCancelButton>
						<Button
							isLoading={viewState === ViewState.isLoading}
							onClick={onSuccess}
						>
							{t("Confirm")}
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
			<SuccessModal />
			{viewState === ViewState.error && (
				<Alert
					color="error"
					title={t("File could not be uploaded")}
					supportingText={t(
						"An unknown error occured when the file was uploaded, please contact support",
					)}
				/>
			)}
			<Outlet />
		</BreadcrumbsLayout>
	);
};
